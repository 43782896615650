import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'


import Bio from "./bio"

library.add(fab)
const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <h1
        style={{
          ...scale(1.5),
          marginBottom: rhythm(1.5),
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h1>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Montserrat, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            textDecoration: `none`,
            color: `inherit`,
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <>
      <div
        style={{
          marginLeft: `auto`,
          marginRight: `auto`,
          maxWidth: rhythm(24),
          padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
        }}
      >
        <header>{header}</header>
        <main>{children}</main>
      </div>
      <footer
        style={{
          backgroundColor: '#212121',
          color: '#fff',
          padding: 40,
        }}
      >
        <div
          className="content-container"
          style={{
            margin: "0 auto"
          }}
        >
          <p className="footer-licence">
            © {new Date().getFullYear()}, chakimar
        </p>
          <Bio />
          <ul
            className="footer-legal"
            style={{
              listStyle: 'none',
              display: 'block',
            }}
          >
            <li
              style={{
                display: 'inline-block',
                paddingRight: 20,
              }}
            >
              <Link
                style={{
                  boxShadow: `none`,
                  textDecoration: `underline`,
                  color: `inherit`,
                }}
                to="/privacy/websites"
              >
                プライバシー
              </Link>
            </li>
          </ul>
        </div>
      </footer>
    </>
  )
}

export default Layout
